const imgs = [
  ["h_1", "h_2", "h_3", "h_4"],
  ["f_1", "f_2", "f_3", "f_4"],
  ["f_5", "b_2", "b_3", "b_4"],
  ["b_1", "p_2", "p_3", "p_4"],
  ["p_5", "p_1", "pro_2", "pro_3"],
  ["m_1", "pro_1", "t_2", "t_3"],
  ["team_5", "team_1", "team_2", "team_3"],
  ["t_1", "team_4", "pro_4", "m_2"]
];

export default imgs;
